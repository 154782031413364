import React from "react";

export default function Button({
  children,
  color,
  height,
  width,
  mb,
  onClick,
  textColor,
  borderColor,
  borderRadius,
  border,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: disabled ? "default" : "pointer",
        color: textColor,
        borderColor,
        border,
        borderRadius,
        height,
        width,
        marginBottom: mb,
        boxShadow: color === "red" && "0 14px 10px 0 rgba(255, 0, 0, 0.18)",
      }}
      className={`text-xs cursor-pointer flex justify-center items-center rounded ${!textColor &&
        color !== "lightgray" &&
        "text-white"} bg-${color}`}
    >
      {children}
    </button>
  );
}
