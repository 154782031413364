import React, { useState, useEffect } from 'react';
import LandingInput from '../fields/LandingInput';
import { navigate, Link } from 'gatsby';
import Loader from 'react-loader-spinner';
import firebase from 'firebase/app';
import 'firebase/auth';
import Form from '../Form';
import * as Yup from 'yup';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

const GatewayTitle = styled.h1`
  font-size: 2.25rem;
  @media only screen and (max-width: 640px) {
    font-size: 1.5rem;
    position: relative;
    top: 15px;
  }
`;

const CANDIDATES_BY_EMAIL = gql`
  query candidateByEmail($email: String) {
    candidate(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export default function NewAccountForm({ isActive }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null });
  const [candidate, setCandidate] = useState();
  const [findCandidateByEmail, { data: candidateEmailData }] = useLazyQuery(CANDIDATES_BY_EMAIL);

  function createAccount({ email, password }) {
    setCandidate({ email, password });
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        if (signInMethods.length === 0) {
          findCandidateByEmail({ variables: { email } });
        } else {
          setErrors({
            password: null,
            email: 'An account with that email already exists',
          });
          setLoading(false);
        }
      })
      .catch();
  }

  useEffect(() => {
    if (candidateEmailData) {
      if (candidateEmailData.candidate.length > 0) {
        setErrors({
          password: null,
          email: 'An account with that email already exists',
        });
      } else {
        navigate('/create-account', { state: candidate });
      }
    }
  }, [candidateEmailData]);

  const passwordErrorMessage = 'Password must be at least 8 characters, have one capital letter, and one special character';

  const fields = {
    email: {
      type: 'email',
      component: LandingInput,
      value: '',
      validation: Yup.string()
        .email('Invalid Email')
        .required('No email provided'),
    },
    password: {
      type: 'password',
      component: LandingInput,
      value: '',
      validation: Yup.string()
        .min(8, passwordErrorMessage)
        .matches(/(?=.*[A-Z])/, passwordErrorMessage)
        .matches(/\W|_/, passwordErrorMessage)
        .required(passwordErrorMessage),
    },
  };

  return (
    <div
      className="flex flex-col items-center px-lg"
      style={{
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <GatewayTitle className="font-main font-thin py-xl text-center">Create Account</GatewayTitle>
      <Form className="flex flex-col items-center" fields={fields} onSubmit={createAccount} customErrors={errors}>
        <div className="flex w-full justify-center">
          <button type="submit" className="bg-red w-full text-center text-white flex justify-center font-main p-md rounded mt-lg w-3/4 cursor-pointer" style={{ height: 56 }}>
            {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Continue'}
          </button>
        </div>
        <div className="mt-md flex justify-center font-main text-white">
          Already have an account?
          <Link to="/login" className="ml-sm font-bold cursor-pointer">
            Log in
          </Link>
        </div>
      </Form>
    </div>
  );
}
