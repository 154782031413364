import React, { useState, useEffect } from 'react';
import ApolloClient from 'apollo-client';
import * as ws from 'ws';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch';
import { WS_HASURA_API_URL, HTTP_HASURA_API_URL } from '../config';

export default function useApollo(token) {
  const [client, setClient] = useState();

  useEffect(() => {
    if (token) {
      const headers = JSON.stringify(token) === JSON.stringify({}) ? {} : { authorization: `Bearer ${token}` };
      const httpLink = new HttpLink({
        uri: HTTP_HASURA_API_URL,
        fetch,
        headers,
      });

      let options = {};
      if (typeof window !== `undefined`) {
        options = {
          uri: WS_HASURA_API_URL, // use wss for a secure endpoint
          options: {
            lazy: true,
            reconnect: true,
            connectionParams: {
              headers,
            },
          },
        };
      } else {
        options = {
          uri: WS_HASURA_API_URL, // use wss for a secure endpoint
          options: {
            lazy: true,
            reconnect: true,
            connectionParams: {
              headers,
            },
          },
          webSocketImpl: ws,
        };
      }

      const wsLink = new WebSocketLink(options);

      const link = split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink,
      );

      // Instantiate client
      const newClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: true,
      });
      setClient(newClient);
    }
  }, [token]);

  return client;
}
