import { gql } from 'apollo-boost';

const CREATE_COMPANY = gql`
  mutation CreateRecruiter($name: String, $size: String, $logoURL: String, $websiteURL: String) {
    insert_company(objects: { name: $name, size: $size, logoURL: $logoURL, websiteURL: $websiteURL }) {
      returning {
        id
        name
      }
    }
  }
`;

const CREATE_COMPANY_USER = gql`
  mutation CreateCompanyUser($adminID: String, $name: String, $email: String, $adminName: String) {
    insert_company(objects: { adminID: $adminID, name: $name, email: $email, adminName: $adminName }) {
      returning {
        id
        name
      }
    }
  }
`;

export { CREATE_COMPANY, CREATE_COMPANY_USER };
