import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import './RichTextArea.css';

export default function RichTextArea({ value, update, placeholder, error, height = 200 }) {
  const [rich, setRich] = useState(value);

  return (
    <div className={`${error ? 'editor-error' : ''} w-full`}>
      <Editor
        apiKey={process.env.GATSBY_TINYMCE_KEY}
        initialValue={rich}
        init={{
          paste_retain_style_properties: ' ',
          directionality: 'ltr',
          height,
        }}
        plugins="lists paste"
        toolbar="bold italic bullist numlist"
        // onEditorChange={update}
        onEditorChange={(newValue, editor) => {
          update(newValue);
        }}
        placeholder={placeholder}
      />
    </div>
  );
}
