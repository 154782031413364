import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import useScript from '../../hooks/useScript';
import PinIcon from '../../images/location.svg';

// function AddCompanyOption ({action}) {
//     return (
//         <div
//             onMouseDown={() => action()}
//             className='p-sm text-sm text-green font-semibold bg-lightgreen w-full flex items-center hover:bg-green hover:text-white cursor-pointer'>
//             <div className='text-lg mr-sm'>+</div>Add New Company
//         </div>)
// }

function SelectDropdown({ value, update, setInputValue, list, error, getInputProps }) {
  const [dropdownVisible, toggleDropdownVisible] = useState(false);

  const filterOptions = (value) => {
    const searchTerm = 'description';
    return value && value.length > 0
      ? list.filter((option) => {
          if (searchTerm) {
            return option[searchTerm].toLowerCase().includes(value.toLowerCase());
          } else {
            return option.toLowerCase().includes(value.toLowerCase());
          }
        })
      : list;
  };

  const setLocation = async (location) => {
    const cityName = `${location.terms[0].value}, ${location.terms[1].value}`;
    const [city] = await geocodeByPlaceId(location.placeId);
    const { lat, lng } = await getLatLng(city);
    update({ city: cityName, lat, lng });
  };

  const options = filterOptions(value);

  return (
    <div className="relative w-full">
      <input
        {...getInputProps({
          onBlur: () => toggleDropdownVisible(false),
          onFocus: () => toggleDropdownVisible(true),
          autoComplete: 'none',
          placeholder: 'City, State',
          className: 'w-full rounded border relative placeholder-primary text-sm px-md',
          style: {
            height: 55,
            marginTop: 20,
            borderColor: error ? '#E31C25' : '#c4cad3',
            zIndex: dropdownVisible ? 300 : 298,
          },
        })}
      />
      {dropdownVisible && (
        <div
          className="absolute bg-white w-full border-r border-l overflow-auto shadow"
          style={{
            top: '100%',
            zIndex: 500,
            maxHeight: 350,
          }}
        >
          {options.length > 0 &&
            options.map((option, index) => (
              <div
                key={index}
                onMouseDown={() => {
                  toggleDropdownVisible(true);
                  setInputValue(option.description);
                  setLocation(option);
                }}
                className="p-sm text-sm border-b cursor-pointer hover:bg-lightgray"
              >
                {option.description}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default function LocationField({ placeholder, value, update, error }) {
  const [location, setLocation] = useState(value);
  const [canRender, setCanRender] = useState(false);
  const [inputValue, setInputValue] = useState(value ? value.city : '');
  const [loaded] = useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_KEY}&libraries=places`);
  useEffect(() => {
    update(location);
  }, [location]);

  new Promise(function(resolve, reject) {
    const interval = setInterval(function() {
      if (window.google) {
        clearInterval(interval);
        resolve(window.google);
        setCanRender(true);
      }
    }, 100);
  });

  console.debug('canRender', canRender, 'loaded', loaded, 'google', window.google);
  return (
    canRender && (
      <PlacesAutocomplete value={inputValue} onChange={setInputValue} searchOptions={{ types: ['(regions)'] }}>
        {({ getInputProps, suggestions }) => {
          return (
            <div className="LocationField-js relative w-full">
              <SelectDropdown value={inputValue} setInputValue={setInputValue} update={setLocation} placeholder="City, State" list={suggestions} getInputProps={getInputProps} />
              <img className="absolute" src={PinIcon} style={{ height: 20, right: 15, top: 37, zIndex: 301 }} />
            </div>
          );
        }}
      </PlacesAutocomplete>
    )
  );
}
