import { useMutation } from '@apollo/react-hooks';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { EXPRESS_SERVER_URL } from '../../config';
import { CREATE_RECRUITER, CREATE_RECRUITER_AND_COMPANY } from '../../mutations';
import AccountDetailsForm from './AccountDetailsForm';
import InterviewAvailabilityForm from './InterviewAvailabilityForm';

export default function CreateAccountForm({ email, password, onSubmit, recruiter }) {
  const accountState = useState(recruiter);
  const [createRecruiter, { data: accountData }] = useMutation(CREATE_RECRUITER);
  const [createRecruiterAndCompany, { data: accountCompanyData }] = useMutation(CREATE_RECRUITER_AND_COMPANY);

  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  // this isn't used in admin/recruiters/new-recruiter
  async function createAccount(recruiter) {
    console.log(`This is EXPRESS_SERVER_URL in createAccount function`);
    console.log(EXPRESS_SERVER_URL);

    const user = await fetch(`${EXPRESS_SERVER_URL}/createRecruiterFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password, name: recruiter.name }),
    }).then((response) => response.json());
    const { industry, name, phone, position, title, profilePhoto, company, days, time } = recruiter;
    const newRecruiter = {
      id: user.uid,
      email,
      name,
      industry,
      phone,
      title,
      position,
      profilePhoto: typeof profilePhoto === 'string' ? profilePhoto : await uploadImage(profilePhoto, 'avatars'),
      availability: {
        schedule: time,
        days,
      },
    };

    if (company.id) {
      createRecruiter({
        variables: { ...newRecruiter, companyID: company.id },
      });
    } else {
      const newRecruiterAndCompany = {
        ...newRecruiter,
        companyLogoURL: typeof company.logoURL === 'string' ? company.logoURL : await uploadImage(company.logoURL, 'companylogos'),
        companyName: company.name,
        companySize: company.size,
        companyWebsiteURL: company.websiteURL,
      };

      createRecruiterAndCompany({ variables: newRecruiterAndCompany });
    }
  }

  useEffect(() => {
    if (accountCompanyData || accountData) {
      firebase.auth().signInWithEmailAndPassword(email, password);
    }
  }, [accountData, accountCompanyData]);

  const slides = {
    [`${recruiter ? 'Edit' : 'Create'} ${firebase.auth().currentUser ? 'Recruiter' : 'Account'}`]: AccountDetailsForm,
    'Interview Availability': InterviewAvailabilityForm,
  };

  return (
    // <FormSlider
    //   action={onSubmit ? onSubmit : createAccount}
    //   slides={slides}
    //   globalFormData={accountState}
    //   isEdit={recruiter ? true : false}
    // />
    <AccountDetailsForm action={onSubmit ? onSubmit : createAccount} globalFormData={accountState} isEdit={recruiter ? true : false} />
  );
}
