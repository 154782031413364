import { Field } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import CandidateSkills from './CandidateSkills';
import CompanyLogoUpload from './CompanyLogoUpload';
import RichTextArea from './RichTextArea';
import TenureField from './TenureField';
import TextInput from './TextInput';

const EmploymentEntry = ({ init, value, update, error }) => {
  const errors = {
    companyLocation: 'The city, state of the company is required',
    title: 'A position title is required',
    companyName: 'A company name is required',
    description: 'A job description is required',
    skills: 'At least one skill is required',
  };

  const placeholders = {
    companyLocation: 'Company City, State',
    title: 'Title',
    companyName: 'Company Name',
    description: 'Job Description',
    startDate: 'Start Date',
    endDate: 'End Date',
    skills: 'Skills',
  };

  const renderField = (name, value) => {
    switch (name) {
      case 'id':
        return null;
      case 'companyLogo':
        return <CompanyLogoUpload value={value} error={!value && error} onFileSelect={(value) => update({ name, data: value })} />;

      case 'companyLocation':
        return <TextInput value={value} width="50%" error={!value && error} placeholder="Company City, State" update={(value) => update({ name, data: value })} />;

      case 'tenure':
        return <TenureField value={value} update={(value) => update({ name, data: value })} hasError={error} />;

      case 'skills':
        return <CandidateSkills value={value} update={(value) => update({ name, data: value })} placeholder="Skills Tags" />;
      case 'description':
        return (
          <div className="w-full">
            <RichTextArea value={value} error={!value && error} update={(value) => update({ name, data: value })} />
          </div>
        );

      default:
        return <TextInput error={!value && error} value={value} placeholder={placeholders[name]} update={(value) => update({ name, data: value })} />;
    }
  };

  return (
    <div className="flex flex-wrap">
      {Object.entries(init).map(([label], index) => {
        return (
          <React.Fragment key={index}>
            {renderField(label, value[label], error && errors[label])}
            {/* {error && errors[label] &&
                            <div className='w-full'>
                                <ErrorMessage error={errors[label]}/>
                            </div>} */}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default function EmploymentField ({ value, update, error, newResumeValue, setFieldValue }) {
  const blankEmploymentEntry = {
    id:
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15),
    companyLogo: '',
    companyLocation: '',
    title: '',
    companyName: '',
    description: '',
    tenure: { startDate: '', endDate: '' },
    skills: [],
  };

  const addExperience = () => update([...value, blankEmploymentEntry]);
  const removeExperience = (index) => update(value.filter((_, i) => index !== i));

  const updateEntry = ({ name, data }, index) => {
    const updatedEntry = Object.fromEntries(Object.entries(value[index]).map(([label, entry]) => (label === name ? [name, data] : [label, entry])));

    const updatedList = value.map((_, i) => (i === index ? updatedEntry : value[i]));

    const experience = updatedList.reduce((totalExperience, { tenure: { startDate, endDate, current } }) => {
      const start = moment(startDate);
      const end = current ? moment() : moment(endDate);
      return totalExperience + end.diff(start, 'years');
    }, 0);

    if (!isNaN(experience)) {
      setFieldValue('experience', experience);
    }

    update(updatedList);
  };

  useEffect(() => {
    if (newResumeValue) {
      update(newResumeValue);
    }
  }, [newResumeValue]);

  return (
    <div>
      {error && <div className="text-red font-medium text-sm mt-sm text-center">{error}</div>}
      {value.map((employmentEntry, index) => {
        return (
          <Field key={index} name={`employment[${index}]`}>
            {({ form: { errors, touched } }) => (
              <React.Fragment key={index}>
                <EmploymentEntry key={employmentEntry.id} init={blankEmploymentEntry} error={error} value={employmentEntry} update={(val) => updateEntry(val, index)} />
                {value.length > 1 && (
                  <>
                    <div className="text-red flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
                      <div onClick={() => removeExperience(index)} className="flex items-center py-sm px-md hover:bg-red hover:text-white rounded cursor-pointer">
                        <span style={{ fontSize: 24, marginRight: 7 }}>-</span>
                        <span>Remove Employment Experience</span>
                      </div>
                    </div>
                    <div className="border-b" style={{ marginTop: 20 }} />
                  </>
                )}
              </React.Fragment>
            )}
          </Field>
        );
      })}
      <div className="text-green flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
        <div onClick={addExperience} className="flex items-center py-sm px-md hover:bg-green hover:text-white rounded cursor-pointer">
          <span style={{ fontSize: 24, marginRight: 7 }}>+</span>
          <span>Add Employment Experience</span>
        </div>
      </div>
    </div>
  );
}
