import React from "react";

export default function Arrow({ direction, color, style, className }) {
  return (
    <div
      className={className}
      style={{
        ...style,
        transform: `rotate(${direction === "down" ? -90 : 0}deg)`,
      }}
    >
      <svg
        style={{ width: 8 }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4.83 8.13"
      >
        <title>Back Arrow</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polyline
              points="4.08 0.75 0.75 4.08 4.04 7.38"
              style={{
                fill: "none",
                stroke: color,
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 1.5,
              }}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
