import React from 'react'

export default function FieldTitle({title, optional}) {
    return (
        <div>
        {title && 
            <div className='text-darkblue w-full font-medium flex justify-between' style={{fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                {title}
                {optional &&
                    <span className='uppercase text-xs text-darkgray'>
                        Optional
                    </span>}
            </div>}
        </div>)
}
