import { gql } from "apollo-boost";

const CREATE_RECRUITER_AND_COMPANY = gql`
  mutation CreateRecruiter(
    $id: String
    $name: String
    $email: String
    $phone: String
    $companyLogoURL: String
    $companyName: String
    $companyWebsiteURL: String
    $companySize: String
    $title: String
    $industry: jsonb
    $profilePhoto: String
    $availability: jsonb
  ) {
    insert_recruiter(
      objects: {
        id: $id
        name: $name
        email: $email
        title: $title
        phone: $phone
        profilePhoto: $profilePhoto
        industries: $industry
        availability: $availability
        company: {
          data: {
            logoURL: $companyLogoURL
            name: $companyName
            size: $companySize
            websiteURL: $companyWebsiteURL
          }
        }
      }
    ) {
      returning {
        id
        name
        company {
          id
          name
          websiteURL
        }
        company_id
      }
    }
  }
`;

const CREATE_RECRUITER = gql`
  mutation CreateRecruiter(
    $id: String
    $name: String
    $email: String
    $phone: String
    $companyID: uuid
    $title: String
    $industry: jsonb
    $profilePhoto: String
    $availability: jsonb
  ) {
    insert_recruiter(
      objects: {
        id: $id
        name: $name
        email: $email
        title: $title
        phone: $phone
        profilePhoto: $profilePhoto
        industries: $industry
        availability: $availability
        company_id: $companyID
      }
    ) {
      returning {
        id
        name
        company_id
      }
    }
  }
`;

// const variables = {
//   email: "joemaverick@recruiting.com",
//   industry: "Healthcare",
//   name: "Joe Maverick",
//   phone: "(555)-555-5555",
//   position: "Recruiter",
//   title: "Recruiter",
//   profilePhoto: "",
//   availability: {
//     schedule: { from: "9:00 AM", to: "5:00 PM" },
//     days: ["monday", "tuesday", "wednesday", "thursday", "friday"],
//   },
// };

export { CREATE_RECRUITER_AND_COMPANY, CREATE_RECRUITER };
