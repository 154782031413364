import React, { useEffect, useState } from 'react';
import { CompensationField } from './CompensationField';
import FieldTitle from './FieldTitle';

export function CompensationList({ value, update, error }) {
  const [required, setRequired] = useState(value ? (typeof value === 'string' ? JSON.parse(value) : value) : []);

  useEffect(() => {
    update(required ? required : []);
  }, [required]);

  return (
    <div>
      <FieldTitle title="Other Perks" />
      <CompensationField value={required} update={setRequired} />
      {error && required.length === 0 && <div className="text-xs text-red font-medium mt-sm text-center">At least one compensation attribute is required</div>}
    </div>
  );
}
