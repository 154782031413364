import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import CaretRight from '../../images/caret-right-solid.svg';
import CheckIcon from '../../images/check-white.svg';

const ALL_SKILLS = gql`
  {
    skill(where: { category: { _eq: "Industry" } }, order_by: [{ category: asc }, { name: asc }]) {
      id
      category
      name
    }
  }
`;

function IndustryAttributesDropdown({ searchValue, industry, attributes, selectedState }) {
  const [open, toggleDropdown] = useState(false);
  const attributesContainerRef = useRef();
  const [attributesContainerHeight, setAttributesContainerHeight] = useState();
  const [selectedAttributes, setSelectedAttributes] = selectedState;

  useEffect(() => {
    if (attributesContainerRef.current) {
      const { height } = getComputedStyle(attributesContainerRef.current);
      setAttributesContainerHeight(parseInt(height.split('px')[0]));
    }
  }, [searchValue]);

  const filteredAttributes = attributes.filter((attribute) => {
    if (searchValue) {
      return attribute.toLowerCase().includes(searchValue.toLowerCase());
    } else {
      return true;
    }
  });

  return filteredAttributes.length > 0 ? (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`${open ? 'border-b' : ''} relative text-darkblue text-sm overflow-hidden`}
      style={{ height: open ? attributesContainerHeight + 39 : 39 }}
    >
      <div onClick={() => toggleDropdown(!open)} className="font-medium border-b p-sm flex items-center cursor-pointer hover:bg-lightgray">
        <img
          alt=""
          src={CaretRight}
          style={{ transition: 'transform 0.2s ease', transform: `rotate(${open ? '90deg' : '0'})`, height: 15, width: 15, marginBottom: 0, marginRight: 10 }}
        />
        {industry}
      </div>
      <div ref={attributesContainerRef} className="absolute w-full bg-white overflow-auto border-b" style={{ maxHeight: 311 }}>
        {filteredAttributes.map((attribute, index) => {
          const selected = selectedAttributes.includes(attribute);
          return (
            <div
              key={index}
              onClick={() => {
                setSelectedAttributes(attribute);
              }}
              className={`cursor-pointer ${selected ? 'bg-green text-white' : 'hover:bg-lightgray'} flex justify-between items-center`}
              style={{ paddingLeft: 33, paddingTop: 5, paddingBottom: 5 }}
            >
              {attribute}
              {selected && <img alt="" src={CheckIcon} style={{ height: 12, width: 12, marginBottom: 0, marginRight: 10 }} />}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

export default function IndustryDropdown({ value, update, error }) {
  const placeholder = 'Industry';
  const [inputValue, setInputValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useQuery(ALL_SKILLS);
  const [allAttributes, setAllAttributes] = useState([]);

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      window.addEventListener('click', hideDropdown);
    } else {
      setInputValue('');
      return window.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (data) {
      setAllAttributes(
        data.skill.reduce((industriesList, attribute) => {
          const category = industriesList[attribute.category];
          if (category) {
            return { ...industriesList, [attribute.category]: [...category, attribute.name] };
          } else {
            return { ...industriesList, [attribute.category]: [attribute.name] };
          }
        }, {}),
      );
    }
  }, [data]);

  return (
    <>
      <div className={`relative`}>
        <input
          onClick={(e) => e.stopPropagation()}
          autoComplete="new-password"
          value={inputValue}
          onFocus={() => setDropdownVisible(true)}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          className="w-full relative rounded border placeholder-primary text-sm px-md"
          style={{ height: '55px', marginTop: '20px', zIndex: dropdownVisible ? 501 : 499, borderColor: error ? '#E31C25' : '#c4cad3' }}
        />
        {dropdownVisible && (
          <div className="absolute bg-white w-full border-r border-l overflow-auto shadow" style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}>
            {Object.entries(allAttributes).map(([industry, attributes], index) => (
              <IndustryAttributesDropdown key={index} selectedState={[[value], update]} searchValue={inputValue} industry={industry} attributes={attributes} />
            ))}
          </div>
        )}
      </div>

      <div className={`w-full flex filter-attributes-container`}>
        <div className={`flex flex-wrap filter-attributes`} style={{ marginTop: 6, top: 40 }}>
          {([value] || []).filter(Boolean).map((term, index) => (
            <div
              key={index}
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10, whiteSpace: 'nowrap' }}
            >
              {term}
              <div
                onClick={() => update(([value] || []).filter((val) => val !== term))}
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}
              >
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
