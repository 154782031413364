import React, { useState, useEffect } from 'react'

const DaySelectOption = ({ dayLetter, active, setActive }) => {
    return (
        <div
            onClick={() => setActive(!active)}
            className={`flex-1 ${active ? "bg-green text-white" : "bg-lightgray"} shadow flex items-center justify-center font-main cursor-pointer text-sm`}
            style={{borderRadius: "100%",marginLeft: 5,marginRight: 5,height: 34}}>
            {dayLetter}
        </div>)
}

export default function DaySelect({value, update}) {
    const initialDays = {
        sunday: { label: "S", active: value ? value.includes('sunday') : false},
        monday: { label: "M", active: value ? value.includes('monday') : false},
        tuesday: { label: "T", active: value ? value.includes('tuesday') : false},
        wednesday: { label: "W", active: value ? value.includes('wednesday') : false},
        thursday: { label: "TR", active: value ? value.includes('thursday') : false},
        friday: { label: "F", active: value ? value.includes('friday') : false},
        saturday: { label: "S", active: value ? value.includes('saturday'): false}
    }
    const [days, setDays] = useState(initialDays)

    useEffect(()=> {
        update(Object.entries(initialDays).filter(([day]) => days[day].active).map(([day]) => day))
    }, [days])

    return (
        <div
            className="flex mb-lg px-md"
            style={{ marginLeft: -10, marginRight: -10 }}>
            {Object.entries(initialDays).map(([dayName]) => (
                <DaySelectOption
                    active={days[dayName]["active"]}
                    setActive={value => setDays({...days,[dayName]: {...days[dayName],active: value}})}
                    dayLetter={days[dayName]["label"]} />))}
        </div>
    )
}
