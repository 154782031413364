import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import fetch from 'node-fetch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { EXPRESS_SERVER_URL, WEBSITE_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';

// graphql query to update the totalCredit and remainingCredit into the company table
const UPDATE_CREDIT = gql`
  mutation updateCompany($id: uuid, $totalCredit: numeric, $remainingCredit: numeric) {
    update_company(where: { id: { _eq: $id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
        subscription_type
      }
    }
  }
`;

// graphql query to create payment history and update the total credits for the company
const CREATE_PAYMENT_HISTORY = gql`
  mutation CreatePayment($credits: numeric, $date: String, $type: smallint, $company_id: uuid, $candidate_id: String, $credit_amount: numeric) {
    insert_payment_history(objects: { credits: $credits, date: $date, type: $type, company_id: $company_id, candidate_id: $candidate_id, credit_amount: $credit_amount }) {
      returning {
        id
        credits
        date
        type
        company_id
        company {
          email
        }
      }
    }
  }
`;

const UPDATE_COMPANY_REQUEST = gql`
  mutation updateCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $credit_amount: numeric, $date: date) {
    update_company_request(
      where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }
      _set: { status: $status, credit_amount: $credit_amount, date: $date }
    ) {
      affected_rows
      returning {
        candidate_id
        company_id
        status
        credit_amount
        date
      }
    }
  }
`;

const INSERT_COMPANY_REQUEST = gql`
  mutation updateCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $credit_amount: numeric, $date: date) {
    insert_company_request(objects: { candidate_id: $candidate_id, company_id: $company_id, status: $status, credit_amount: $credit_amount, date: $date }) {
      returning {
        candidate_id
        company_id
        status
        credit_amount
        date
      }
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

export default function ApprovalConfirmModal ({ state }) {
  const [modal, setModal] = state;
  const [, setFadeDelay] = useState(false);
  const modalRef = useRef();

  const [emailData, setEmailData] = useState();
  const [flag, setFlag] = useState(false);

  // set current date
  const [date] = useState(new Date());

  // calling mutation when submit the modal having totalCredit
  const [updateCompanyCredit, { data: submissionResponse, loading }] = useMutation(UPDATE_CREDIT);

  // mutaion to update the status in the company_request table
  const [updateCandidateRequest] = useMutation(UPDATE_COMPANY_REQUEST);

  // mutaion to update the status in the company_request table
  const [insertCandidateRequest] = useMutation(INSERT_COMPANY_REQUEST);

  // mutation to create new payment history and update the credits in company
  const [createPaymentHistory, { data: paymentResponseData }] = useMutation(CREATE_PAYMENT_HISTORY);

  const { apolloClient } = useContext(ApolloContext);

  useEffect(() => {
    if (modal.open) {
      if (modal.data.candidate && modal.data.candidate.recruiter && modal.data.candidate.recruiter.notification_settings) {
        if (modal.data.candidate.recruiter.notification_settings.notification_ids.includes(1)) {
          setFlag(true);
        }
      } else {
        setFlag(false);
      }

      setFadeDelay(true);
      setEmailData(modal.data);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  // update credit handler (form submit), called when click on the yes from the modal
  const updateCredit = async (evt) => {
    evt.preventDefault();

    // Check if enough credits are available
    const response = await apolloClient.query({
      query: GET_COMPANY_ID,
      variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
    });

    // If paid account, then don't check available credit
    if (response.data.company[0].subscription_type === 'PAID') {
      //
    } else {
      if (response.data.company[0].remainingCredit <= 0) {
        setModal({ ...modal, open: false, reset: true });

        return;
      }
    }

    // Check if company request exist. If yes, then update, else insert
    const companyRequestExist = await apolloClient.query({
      query: gql`
        query getCompanyRequest($companyId: uuid, $candidateId: String) {
          company_request(where: { company_id: { _eq: $companyId }, candidate_id: { _eq: $candidateId } }) {
            company_id
            candidate_id
          }
        }
      `,
      variables: { candidateId: modal.data.candidateID, companyId: modal.data.subscriber.id },
    });

    if (companyRequestExist.data.company_request.length) {
      updateCandidateRequest({
        variables: {
          candidate_id: modal.data.candidateID,
          company_id: modal.data.subscriber.id,
          status: 'approved',
          credit_amount: 1,
          date: moment(date).format('YYYY-MM-DD'),
        },
      });
    } else {
      insertCandidateRequest({
        variables: {
          candidate_id: modal.data.candidateID,
          company_id: modal.data.subscriber.id,
          status: 'approved',
          credit_amount: 1,
          date: moment(date).format('YYYY-MM-DD'),
        },
      });
    }

    // TODO: In the middle of implementing this it was brought to my attention that this would unlock all jobs for the user for this subscriber. This wouldn't make sense
    // Check if job candidate request exists. If so, set it to approved
    // const jobRequestExists = await apolloClient.query({
    //   query: gql`
    //     query getJobCandidateRequest($companyId: uuid, $candidateId: String) {
    //       job_candidate(where: { candidate_id: { _eq: $candidateId }, status: {_eq: 'interested'}, company_requests: {company_id: { _eq: $companyId }, } }) {
    //         job_id
    //         candidate_id
    //         status
    //       }
    //     }
    //   `,
    //   variables: { candidateId: modal.data.candidateID, companyId: modal.data.subscriber.id },
    // });

    // if (jobRequestExists.data.job_candidate.length) {
    //   jobRequestExists.data.job_candidate.map(function () {
    //     updateJobCandidateRequest({
    //       variables: {
    //         candidate_id: modal.data.candidateID,
    //         job_id: modal.data.subscriber.id,
    //         status: 'approved',
    //       },
    //     });
    //   });
    // }


    // If paid account, then don't deduct available credit
    if (response.data.company[0].subscription_type === 'PAID') {
      setModal({ open: false });
    } else {
      updateCompanyCredit({
        variables: {
          id: modal.data.subscriber.id,
          remainingCredit: parseInt(modal.data.subscriber.remainingCredit) - 1,
          totalCredit: parseInt(modal.data.subscriber.totalCredit) - 1,
        },
      });
    }

    // removing the record in payment history table
    const dateString = moment(date).format('YYYY-MM-DD');
    const paymentHistory = {
      credits: 1,
      date: dateString,
      type: 2,
      company_id: modal.data.subscriber.id,
      candidate_id: modal.data.candidateID,
      credit_amount: 1,
    };
    createPaymentHistory({
      variables: paymentHistory,
    });
  };

  useEffect(() => {
    if (paymentResponseData) {
      let email;
      if (emailData && emailData.candidate && emailData.candidate.recruiter && emailData.candidate.recruiter.email) {
        email = emailData.candidate.recruiter.email;
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      const imagelink =
        'https://firebasestorage.googleapis.com/v0/b/cherry-picker-a3314.appspot.com/o/images%2Fheader-logo.png?alt=media&token=defa4e61-56b0-438b-aaa1-9572fb2f8691';
      const content = `
                <div style="border: 2px solid red;padding: 20px;">
                <img src=${imagelink} style="max-width: 250px;width: 50%;margin:0px" />
                <p style="margin-top:10px;font-size:17px">Hi ${emailData && emailData.candidate && emailData.candidate.recruiter ? emailData.candidate.recruiter.name : ''}</p>
                <p style="margin-top:10px;font-size:17px">Great news! ${emailData && emailData.subscriber ? emailData.subscriber.name : 'Subscriber'} used a credit to unlock ${emailData && emailData.candidate ? emailData.candidate.name : 'Candidate'
        }'s profile.</p>
                <p style="margin-top:25px;font-size:17px">You can keep track of all your candidates that get introduced to our Company Subscribers by going to the "Report" tab.
                <a href="${WEBSITE_URL}/dashboard/job-queue" target="_blank">
                    ${WEBSITE_URL}/dashboard/job-queue
                </a>
                </p>
                <p style="margin-top:25px;font-size:17px">If you have any additional questions or have issues accessing your account--please contact support at <a href="mailto:support@getcherrypicker.com">support@getcherrypicker.com</a></p>
                <p style="margin-top:25px;font-size:17px">Regards,<br>Cherrypicker Support Team</p>
                </div>
            `;
      const subject = 'Company unlocks your candidate profile';
      const text = 'dummy';

      if (flag) {
        fetch(`${EXPRESS_SERVER_URL}/sendMail`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email, subject, content, text }) });
      }
    }
  }, [paymentResponseData]);

  // when updateCredit handler submits successfully then closing the modal
  useEffect(() => {
    if (submissionResponse) {
      setModal({ open: false });
    }
  }, [submissionResponse]);

  return (
    <React.Fragment>
      <form onSubmit={updateCredit}>
        <div
          onClick={() => setModal({ ...modal, open: true, reset: true })}
          className={`approval-confirm-modal-js fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
          style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000000000000000 }}
        >
          <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col">
            <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
              Confirm Candidate Approval
              <div onClick={() => setModal({ open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
                x
              </div>
            </div>
            <div className="text-darkblue text-sm text-center py-lg">Are you sure you want to unlock this candidate by spending 1 credit ?</div>
            <div className="flex justify-center pt-lg">
              <button
                onClick={() => setModal({ ...modal, open: false, reset: true })}
                type="button"
                className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                style={{ width: 137, height: 55, fontSize: 14 }}
              >
                Cancel
              </button>
              <button
                className="rounded bg-red ml-md font-medium flex justify-center items-center text-sm text-white"
                style={{ width: 180, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
              >
                {loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
