import React, { useEffect, useRef, useState } from 'react';
import UserAvatar from '../../images/user-avatar.png';

export default function SelectDropdown({ list, inputProps, dontClear, value, placeholder, action, update, error, keepOpenOnSelect, paddingLeft }) {
  const [filteredOptions, setFilteredOptions] = useState(list);
  const [dropdownVisible, toggleDropdownVisible] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef();

  const filterOptions = (value) => {
    setInputValue(value);
    if (placeholder === 'Company' || placeholder === 'Select Recruiter') {
      setFilteredOptions(value ? list.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())) : list);
    } else {
      setFilteredOptions(value ? list.filter((option) => option.toLowerCase().includes(value.toLowerCase())) : list);
    }
  };

  useEffect(() => {
    setFilteredOptions(list);
  }, [list]);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className="relative w-full font-main">
      <input
        autoComplete="new-password"
        ref={inputRef}
        value={inputValue ? inputValue : ''}
        onFocus={() => toggleDropdownVisible(true)}
        onBlur={(e) => {
          toggleDropdownVisible(false);
        }}
        onChange={(e) => filterOptions(e.target.value)}
        placeholder={placeholder}
        className={`w-full relative rounded border placeholder-primary text-sm px-md`}
        style={{
          height: placeholder === 'Search for category' ? 40 : 55,
          marginTop: 0,
          zIndex: dropdownVisible ? 501 : 499,
          borderColor: error ? '#E31C25' : '#c4cad3',
          paddingRight: paddingLeft,
        }}
        {...inputProps}
      />
      {dropdownVisible && (
        <div className="absolute bg-white w-full border-r border-l overflow-auto shadow" style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}>
          {placeholder === 'Company' && (
            <div
              onMouseDown={() => action()}
              className="p-sm text-sm text-green font-semibold bg-lightgreen w-full flex items-center hover:bg-green hover:text-white cursor-pointer"
            >
              <div className="text-lg mr-sm">+</div>
              Add New Company
            </div>
          )}
          {placeholder === 'Category' && (
            <div
              onMouseDown={() => action()}
              className="p-sm text-sm text-green font-semibold bg-lightgreen w-full flex items-center hover:bg-green hover:text-white cursor-pointer"
            >
              <div className="text-lg mr-sm">+</div>
              Add New Category
            </div>
          )}
          {filteredOptions.length > 0 &&
            filteredOptions.map((option) => (
              <div
                onMouseDown={(e) => {
                  if (keepOpenOnSelect) {
                    action(option);
                  } else {
                    if (placeholder === 'Company' || placeholder === 'Select Recruiter') {
                      setInputValue(option.name);
                      update(option);
                    } else {
                      update(option);
                      setInputValue(dontClear ? option : '');
                    }
                  }
                }}
                className="p-sm text-sm border-b cursor-pointer hover:bg-lightgray"
              >
                {placeholder === 'Company' || placeholder === 'Select Recruiter' ? (
                  <div className="flex items-center">
                    {placeholder === 'Select Recruiter' ? (
                      <div
                        className="mr-md"
                        style={{
                          width: 30,
                          height: 30,
                          backgroundImage: `url(${option.profilePhoto ? option.profilePhoto : UserAvatar})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: 15,
                        }}
                      />
                    ) : (
                      <img
                        alt=""
                        className="mr-md"
                        src={placeholder === 'Company' ? option.logoURL : option.profilePhoto ? option.profilePhoto : UserAvatar}
                        style={{ width: 30, marginBottom: 0 }}
                      />
                    )}
                    {option.name}
                  </div>
                ) : (
                  option
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
