import { navigate } from 'gatsby';
import React from 'react';
import { Arrow } from '.';

export default function TabTitle({ children, marginBottom, showBackButton }) {
  if (showBackButton) {
    return (
      <>
        <div onClick={() => navigate(-1)} className="flex text-darkgray font-medium text-xs items-center cursor-pointer hover:underline" style={{ marginRight: 10 }}>
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
          Back
        </div>
        <div className="lg:m-0 capitalize flex items-center flex-row justify-between w-full" style={{ fontSize: 21, fontWeight: 500, marginBottom }}>
          {children}
        </div>
      </>
    );
  }

  return (
    <div className="lg:m-0 capitalize flex items-center flex-row justify-between w-full" style={{ fontSize: 21, fontWeight: 500, marginBottom }}>
      {children}
    </div>
  );
}
