import React from 'react';

export default function AlertIcon({ active }) {
  return (
    <svg style={{ height: 30, margin: 0, marginRight: 15, cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <title>Alert</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle className="cls-1" fill={active ? '#6ACA63' : '#f1f4f8'} cx="20" cy="20" r="20" />
          <path
            className="cls-2"
            fill={active ? 'white' : '#9499a2'}
            d="M13,19.79v3.72a1.92,1.92,0,0,0,.64,3.72h3.26a3.18,3.18,0,0,0,6.24,0h3.26A1.92,1.92,0,0,0,27,23.51V19.79A7,7,0,0,0,21.71,13a1.84,1.84,0,0,0,.2-.85,1.91,1.91,0,1,0-3.82,0,1.84,1.84,0,0,0,.2.85A7,7,0,0,0,13,19.79Zm7,8.71a1.9,1.9,0,0,1-1.8-1.28h3.6A1.9,1.9,0,0,1,20,28.5Zm-.64-16.37a.64.64,0,1,1,.64.64A.64.64,0,0,1,19.36,12.13ZM20,14.05a5.74,5.74,0,0,1,5.74,5.74V23.4H25.1a.64.64,0,0,0,0,1.28h1.28a.64.64,0,1,1,0,1.27H13.62a.64.64,0,1,1,0-1.27H14.9a.64.64,0,0,0,0-1.28h-.64V19.79A5.74,5.74,0,0,1,20,14.05Z"
          />
        </g>
      </g>
    </svg>
  );
}
