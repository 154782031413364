// https://github.com/ricardo-ch/react-easy-crop
// https://www.youtube.com/watch?v=MWzaItRRTXw&t=2068
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import UploadIcon from '../../images/upload-icon.svg';
import getCroppedImg from './getCroppedImg';

export default function PhotoUpload({ value, placeholder, update, width, error }) {
  const uploadRef = useRef();
  const [currentPhotoURL, setCurrentPhotoURL] = useState(value);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uncroppedPhoto, setUncroppedPhoto] = useState(null);
  const [uncroppedPhotoURL, setUncroppedPhotoURL] = useState(null);

  useEffect(() => {
    if (uncroppedPhoto) {
      setUncroppedPhotoURL(URL.createObjectURL(uncroppedPhoto));
    }
  }, [uncroppedPhoto]);

  function onCropChange(crop) {
    setCrop(crop);
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const saveCroppedImage = useCallback(async () => {
    try {
      const { file, url } = await getCroppedImg(uncroppedPhotoURL, croppedAreaPixels);
      file.name = url;
      setUncroppedPhoto(null);
      setUncroppedPhotoURL(null);
      setCurrentPhotoURL(url);
      update(file);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  function onZoomChange(zoom) {
    setZoom(zoom);
  }

  return (
    // if the user chose a picture from there files, it needs to be cropped still
    <div className="PhotoUpload-js flex-col flex w-full items-center justify-center mt-md relative" style={width}>
      {uncroppedPhoto ? (
        <div className="Photo_cropping_container h-64 w-full mb-4 relative flex flex-col items-center justify-end">
          <Cropper
            image={uncroppedPhotoURL}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            restrictPosition={false}
          />
          <div className="PhotoUpload_controls w-40 text-center">
            <Slider value={zoom} min={0.5} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom) => onZoomChange(zoom)} classes={{ container: 'PhotoUpload_slider' }} />

            <Button onClick={saveCroppedImage} variant="contained" color="primary">
              Save
            </Button>
          </div>
        </div>
      ) : // after cropping, it becomes the photo
      currentPhotoURL ? (
        <div onClick={() => setCurrentPhotoURL(null)}>
          <div
            style={{
              margin: '0 auto',
              backgroundImage: `url('${currentPhotoURL}')`,
              height: 100,
              width: 100,
              borderRadius: 50,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              cursor: 'pointer',
            }}
          ></div>
          <div className="font-main text-xs text-darkgray cursor-pointer">Choose another photo</div>
        </div>
      ) : (
        // if the user hasn't picked a photo yet
        <>
          <div
            onClick={() => uploadRef.current.click()}
            className={`mb-4 w-full mt-md rounded cursor-pointer bg-lightgray flex font-main text-darkgray text-sm items-center justify-between px-md ${
              error ? 'border border-red' : ''
            }`}
            style={{ height: 55 }}
          >
            {placeholder}
            <img src={UploadIcon} style={{ height: 22, margin: 0 }} />
            <input onChange={({ target: { files } }) => setUncroppedPhoto(files[0])} ref={uploadRef} type="file" accept=".png,.jpg,.jpeg" style={{ display: 'none' }} />
          </div>
        </>
      )}
    </div>
  );
}
