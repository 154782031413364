import React, { useState, useRef, useEffect } from "react";

export default function SocialInput(props) {
  const { error, value, update, placeholder } = props;

  const prefixRef = useRef();
  const [prefixWidth, setPrefixWidth] = useState(0); /*eslint-disable-line*/
  useEffect(() => {
    setPrefixWidth(
      parseFloat(
        window.getComputedStyle(prefixRef.current).width.split("px")[0]
      ) +
      15 +
      "px"
    );
  }, []);

  return (
    <div className="w-full flex relative">
      <div
        ref={prefixRef}
        className="border justify-center rounded bg-lightgray text-sm text-darkgray px-md flex items-center"
        style={{
          marginTop: 20,
          borderColor: error ? "#E31C25" : "rgb(196, 202, 211)",
          width: 215.5,
          marginRight: -7,
          borderRight: "none",
          borderRadius: "0.25rem 0px 0px 0.25rem",
        }}>
        {placeholder === "linkedin" || placeholder === "Linkedin Handle"
          ? "LinkedIn"
          : placeholder === "Instagram Profile Handle" ||
            placeholder === "instagram"
            ? "https://instagram.com/"
            : placeholder === "Facebook Profile Handle" ||
              placeholder === "facebook"
              ? "Facebook"
              : "https://twitter.com/"}
        {/* {placeholder === "linkedin" || placeholder === "Linkedin Handle"
          ? "https://linkedin.com/in/"
          : placeholder === "Instagram Profile Handle" ||
            placeholder === "instagram"
          ? "https://instagram.com/"
          : placeholder === "Facebook Profile Handle" ||
            placeholder === "facebook"
          ? "https://facebook.com/"
          : "https://twitter.com/"} */}
      </div>
      <input
        autoComplete="asdkfjalsdf"
        onChange={e => update && update(e.target.value)}
        value={value}
        placeholder={(placeholder === "linkedin" || placeholder === "Linkedin Handle") ? "LinkedIn Link" : (placeholder == "Facebook Profile Handle" || placeholder == "facebook") ? "Facebook Link" : placeholder}
        className={`bg-white text-input w-full rounded border relative font-main placeholder-primary text-sm px-md`}
        style={{
          height: 55,
          marginTop: 20,
          borderColor: error ? "#E31C25" : "#c4cad3",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />
    </div>
  );
}
