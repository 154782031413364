import React from 'react';
import SearchIcon from '../../images/search.svg';

export default function SearchBox({ value, placeholder, update }) {
  return (
    <div className="relative " style={{ maxWidth: 310 }}>
      <img src={SearchIcon} style={{ position: 'absolute', left: 10, top: 11, width: 16 }} />
      <input
        value={value}
        onChange={(e) => update(e.target.value)}
        style={{ height: 40, paddingLeft: 35 }}
        className="SearchBox-js bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
        placeholder={placeholder}
      />
    </div>
  );
}
