import React, { useState } from "react";
import LandingInput from "../fields/LandingInput";
import { Link } from "gatsby";
import firebase from "firebase/app";
import styled from "styled-components";

const GatewayTitle = styled.h1`
  font-size: 2.25rem;
  @media only screen and (max-width: 640px) {
    font-size: 1.5rem;
    position: relative;
    top: 15px;
  }
`;

export default function ResetPasswordForm({ isActive }) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const sendPasswordResetLink = () => {
    if (!success) {
      if (email) {
        firebase
          .auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            setError();
            setSuccess(true);
          })
          .catch(({ code }) => {
            switch (code) {
              case "auth/invalid-email":
                setError("Please enter a valid email address");
                break;
              case "auth/user-not-found":
                setError("A user with that email was not found.");
                break;
              default:
                break;
            }
          });
      } else {
        setError("Please enter your account's email");
      }
    }
  };

  return (
    <div
      className="flex flex-col items-center w-full px-lg"
      style={{
        opacity: isActive ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
      }}>
      <GatewayTitle className="font-main font-thin py-xl text-center w-full">
        Reset Password
      </GatewayTitle>
      <div className="flex flex-col items-center w-full">
        <LandingInput error={error} placeholder="Email" update={setEmail} />
        {success && (
          <div
            className="mb-md text-white text-center text-sm rounded px-md py-sm bg-green" /* style={{backgroundColor: 'rgba(255,255,255,0.7)'}} */
          >
            A link to reset your password <br />
            has been sent to your email
          </div>
        )}
        <button
          onClick={sendPasswordResetLink}
          className=" w-1/4 sm:w-full bg-red text-white font-main p-md rounded mt-lg w-full cursor-pointer">
          Reset Password
        </button>
        <div className="mt-md flex justify-center font-main text-white">
          Already have an account?
          <Link className="ml-sm font-bold cursor-pointer" to="/login">
            Log in
          </Link>
        </div>
      </div>
    </div>
  );
}
