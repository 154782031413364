import React, { useState, useEffect } from 'react'

function TimeSelectOption({ value, update, type }) {
    const hours = ["1","2","3","4","5","6","7","8","9","10","11","12",]
    return (
        <div className={`flex-1 ${type === 'from' ? 'mr' : 'ml'}-sm`}>
            <div
                className="font-main mb-sm uppercase capitalize text-center text-sm"
                style={{fontWeight: 500}}>
                {type}
            </div>
            <select
                className="w-full p-md mb-lg font-main border border-darkgray bg-white"
                onChange={e => update(e.target.value)}
                value={value}
            >
                {hours.map(hour => (
                    <option>{`${hour}:00 AM`}</option>
                ))}
                {hours.map(hour => (
                    <option>{`${hour}:00 PM`}</option>
                ))}
            </select>
        </div>)
}

export default function TimeSelect ({value, update}) {
    const [schedule, setSchedule] = useState(value ? value : {from: "9:00 AM", to: "5:00 PM" })

    useEffect(() => {
        update(schedule)
    }, [schedule])

    return(
    <div className="flex w-full">
        <TimeSelectOption
            type='from'
            value={schedule.from}
            update={time =>setSchedule({...schedule,from: time,})}/>
        <TimeSelectOption
            type='to'
            value={schedule.to}
            update={time =>setSchedule({...schedule, to: time,})}/>
    </div>)
}