import React, { useContext } from "react";
import Loader from "react-loader-spinner";

const ConfirmModalContext = React.createContext();

export { ConfirmModalContext };

export default function ConfirmModal({ children }) { 
  const [modal, setModal] = useContext(ConfirmModalContext);
  const loading = false;
  return (
    <React.Fragment>
      {children}
      <div
        onClick={() => setModal({ open: false })}
        className={`fixed inset-0 ${
          modal.open ? "flex" : "hidden"
        } items-center justify-center font-main`}
        style={{ backgroundColor: "rgba(34, 46, 66, 0.9)", zIndex: 20000000000000000 }}>
        <div
          onClick={e => e.stopPropagation()}
          className="bg-white p-lg rounded shadow flex flex-col"
          style={{ maxWidth: "calc(100vw - 30px)" }}>
          <div
            className="text-darkblue font-medium border-b flex justify-between pb-sm"
            style={{ maxWidth: "calc(100vw - 30px)" }}>
            {modal.title}
            <div
              onClick={() => setModal({ open: false })}
              className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            {modal.message}
          </div>
          <div className="flex justify-center pt-lg">
            <button
              onClick={() => setModal({ open: false })}
              type="button"
              className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
              style={{ width: 137, height: 55, fontSize: 14 }}>
              {modal.cancelText ? modal.cancelText : 'Cancel'}
            </button>
            <button
              onClick={() => {
                modal.action();
                if(!modal.closeInstant) {
                  setModal({
                    ...modal,
                    loading: true,
                  });
                }
              }}
              className="rounded bg-red ml-md font-medium flex justify-center items-center text-sm text-white"
              style={{
                width: 180,
                height: 55,
                fontSize: 14,
                boxShadow: "0 14px 10px 0 rgba(255, 0, 0, 0.18)",
              }}>
              {modal.loading ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                />
              ) : (
                modal.buttonText
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
