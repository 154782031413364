import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AttributesDropdown from './AttributesDropdown';

const ALL_CERTIFICATIONS = gql`
  {
    certification {
      name
    }
  }
`;

const Input = styled.input`
  ::placeholder {
    text-transform: capitalize;
  }
`;

const CertificationsDropDown = ({ inputState, update, attributes, list, placeholder, error }) => {
  const [dropdownOpen, toggleDropdown] = useState(false);
  const [inputValue, setInputValue] = inputState;
  const { data } = useQuery(ALL_CERTIFICATIONS);
  const [certifications, setCertifications] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setCertifications(data.certification.map(({ name }) => name));
      setOptions(data.certification.map(({ name }) => name));
    }
  }, [data]);

  const filteredCertifications = certifications.filter((certification) => {
    if (inputValue) {
      return certification.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  });

  return (
    <div className="relative w-full" style={{ marginTop: 15 }}>
      <Input
        value={inputValue}
        onFocus={() => toggleDropdown(true)}
        onBlur={() => toggleDropdown(false)}
        onChange={(e) => {
          const text = e.target.value;
          setInputValue(text);
        }}
        placeholder={placeholder}
        className="rounded relative border w-full text-sm"
        style={{
          zIndex: dropdownOpen ? 501 : 499,
          height: 55,
          paddingLeft: 15,
          paddingRight: 15,
          borderColor: error ? '#E31C25' : '#c4cad3',
        }}
      />
      {dropdownOpen && options.length > 0 && (
        <div
          className="absolute bg-white w-full border-r border-l overflow-auto shadow"
          style={{
            top: 'calc(100% - 10px)',
            zIndex: 500,
            paddingTop: 10,
            maxHeight: 350,
          }}
        >
          {filteredCertifications
            .filter((skill) => !attributes.includes(skill))
            .map((option) => (
              <div
                onMouseDown={() => {
                  setInputValue('');
                  update([option, ...attributes]);
                }}
                className="p-sm text-sm border-b cursor-pointer hover:bg-lightgray"
              >
                {option}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const Xbutton = ({ close }) => {
  return (
    <div onClick={close} className="ml-sm text-sm relative" style={{ left: 5, width: 10, height: 10 }}>
      <div
        className="bg-darkgray absolute"
        style={{
          height: 10,
          width: 1.5,
          transform: 'rotate(45deg)',
          borderRadius: 1.5,
        }}
      />
      <div
        className="bg-darkgray absolute"
        style={{
          height: 10,
          width: 1.5,
          transform: 'rotate(-45deg)',
          borderRadius: 1.5,
        }}
      />
    </div>
  );
};

export default function CandidateSkills({ value, update, placeholder, error, newResumeValue, type }) {
  const [skills, setSkills] = useState(value ? value : []);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    update(skills);
  }, [skills]);

  useEffect(() => {
    if (newResumeValue) {
      setSkills(newResumeValue);
    }
  }, [newResumeValue]);

  return (
    <div className="flex flex-col w-full items-center">
      <div className="flex items-center w-full">
        {type === 'certifications' && (
          <CertificationsDropDown error={error} inputState={[inputValue, setInputValue]} value={inputValue} update={setSkills} attributes={skills} placeholder={placeholder} />
        )}
        {type === 'skills' && (
          <AttributesDropdown
            value={skills}
            update={setSkills}
            error={error}
            containerStyle={{
              width: '100%',
              marginTop: 15,
            }}
            className="rounded relative border w-full text-sm"
            style={{
              height: 55,
              paddingLeft: 15,
              paddingRight: 15,
              borderColor: error ? '#E31C25' : '#c4cad3',
            }}
          />
        )}
      </div>
      {skills.length > 0 && (
        <div className="flex flex-wrap w-full" style={{ marginTop: 10 }}>
          {skills.map((skill, index) => (
            <div
              key={index}
              className="px-md cursor-pointer bg-lightgray mt-sm flex justify-between mr-sm font-medium text-darkgray items-center"
              style={{ fontSize: 12, height: 28, borderRadius: 28 }}
            >
              {skill}
              <Xbutton
                close={() => {
                  setSkills(skills.filter((val) => val !== skill));
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
