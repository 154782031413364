import { AsYouType } from 'libphonenumber-js';
import React, { useRef } from 'react';
import TextInput from './TextInput';

export default function PhoneInput({ value, update, error }) {
  const textRef = useRef();
  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber.length < 15) {
      let phoneInput = '';
      if (phoneNumber.length < value.length && value[value.length - 1] === ')') {
        phoneInput = phoneNumber[0] + phoneNumber[1] + phoneNumber[2];
      } else {
        phoneInput = phoneNumber.substr(0, 15);
      }
      const formatter = new AsYouType('US');
      const newNumber = phoneInput.replace(/\D/g, '');
      const formattedNumber = formatter.input(newNumber);
      update(formattedNumber);
    }
  };

  return <TextInput textRef={textRef} error={error} value={value} placeholder="Phone Number" update={validatePhoneNumber} />;
}
