import React from 'react'
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

export default function Loading () {
    return (
        <div
            className="fixed inset-0 flex items-center justify-center"
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <Loader type="TailSpin" color="#FFFFFF" height={50} width={50} />
        </div>
    )
}