import { useMutation } from '@apollo/react-hooks';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Link, navigate } from 'gatsby';
import { request } from 'graphql-request';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import { HTTP_HASURA_API_URL } from '../../config';
import { CREATE_COMPANY_USER } from '../../mutations/company';
import LandingInput from '../fields/LandingInput';
import Form from '../Form';

export default function NewCompanyAccountForm({ isActive }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null });
  const [createCompanyUser] = useMutation(CREATE_COMPANY_USER);

  async function createAccount({ companyName, name, email, password }) {
    const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);

    request(
      HTTP_HASURA_API_URL,
      `
  mutation CreateCompanyUser(
    $adminID: String
    $name: String
    $email: String
    $adminName: String
  ) {
    insert_company(
      objects: {
        adminID: $adminID
        name: $name
        email: $email
        adminName: $adminName
      }
    ) {
      returning {
        id
        name
      }
    }
  }
`,
      { adminID: user.uid, email, name: companyName, adminName: name },
    )
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => {
            navigate('/dashboard');
          });
      })
      .catch(console.log);
  }

  function createCompanyAccount({ companyName, name, email, password }) {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        if (signInMethods.length === 0) {
          createAccount({ companyName, name, email, password });
        } else {
          setErrors({
            password: null,
            email: 'An account with that email already exists',
          });
          setLoading(false);
        }
      })
      .catch(console.log);
  }

  const passwordErrorMessage = 'Password must be at least 8 characters, have one capital letter, and one special character';

  const fields = {
    companyName: {
      type: 'text',
      component: LandingInput,
      placeholder: 'Company Name',
      value: '',
      validation: Yup.string().required('No company name provided'),
    },
    name: {
      type: 'text',
      component: LandingInput,
      value: '',
      validation: Yup.string().required('No name provided'),
    },
    email: {
      type: 'email',
      component: LandingInput,
      value: '',
      validation: Yup.string()
        .email('Invalid Email')
        .required('No email provided'),
    },
    password: {
      type: 'password',
      component: LandingInput,
      value: '',
      validation: Yup.string()
        .min(8, passwordErrorMessage)
        .matches(/(?=.*[A-Z])/, passwordErrorMessage)
        .matches(/\W|_/, passwordErrorMessage)
        .required(passwordErrorMessage),
    },
  };

  return (
    <div
      className="flex flex-col items-center"
      style={{
        opacity: isActive ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <h1 className="text-4xl font-main font-thin py-xl text-center">Sign Up</h1>
      <Form fields={fields} onSubmit={createCompanyAccount} customErrors={errors}>
        <div className="flex justify-center">
          <button type="submit" className="bg-red text-center text-white flex justify-center font-main p-md rounded mt-lg w-3/4 cursor-pointer" style={{ height: 56 }}>
            {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Continue'}
          </button>
        </div>
        <div className="mt-md flex justify-center font-main text-white">
          Already have an account?
          <Link to="/login" className="ml-sm font-bold cursor-pointer">
            Log in
          </Link>
        </div>
      </Form>
    </div>
  );
}
