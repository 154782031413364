import { useEffect, useState } from 'react';
const config = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASEURL,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.GATSBY_FIREBASE_APPID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
};

export default function useFirebase() {
  const [initializingFirebase, setFirebaseInitialization] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [token, setToken] = useState();

  async function initializeFirebase() {
    const firebase = await import('firebase/app');
    await import('firebase/database');
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();

        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];
        if (hasuraClaim) {
          console.debug('Got Hasura Claim');
          setToken(idToken);
        } else {
          console.debug('No Hasura Claim');
          // Setting this by default because for some reason the value is not coming from the metadataRef
          const metadataRef = firebase.database().ref('metadata/' + user.uid + '/refreshTime');
          metadataRef.on(
            'value',
            async () => {
              const idToken = await user.getIdToken(true);
              setToken(idToken);
            },
            function(error) {
              console.error('Metadata Ref Error', error);
            },
          );
        }

        setCurrentUser(user);
        setFirebaseInitialization(false);
      } else {
        setToken({});
        setCurrentUser();
        setFirebaseInitialization(false);
      }
    });
  }

  useEffect(() => {
    initializeFirebase();
  }, []);
  return { initializingFirebase, currentUser, token };
}
