import React, { useState, useEffect } from "react";

export default function Checkbox({ value, update, label }) {
  const [checked, toggleChecked] = useState(value ? value : false);

  useEffect(() => {
    update(checked);
  }, [checked]);

  return (
    <div className="flex mt-md items-center">
      <input
        className="cursor-pointer"
        type="checkbox"
        checked={checked}
        onChange={() => toggleChecked(!checked)}
      />
      <div
        className="pl-sm text-sm font-medium text-darkblue"
        style={{ position: "relative", top: 2 }}
      >
        {label}
      </div>
    </div>
  );
}
