import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const GET_COMPANY = gql`
  query getCompany($id: String) {
    company(where: { adminID: { _eq: $id } }) {
      id
    }
  }
`;

export default function useCompanyCheck() {
  const { data, loading } = useQuery(GET_COMPANY, {
    variables: {
      id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  const companyCheck = () => {
    if (loading) {
      return null;
    } else if (data && Object.keys(data).includes("company")) {
      const isCo = data.company.length > 0;
      return isCo;
    }
  };

  const isCompany = companyCheck();

  return { isCompany, loading };
}
