import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import ErrorMessage from './fields/ErrorMessage';

export default function Form(props) {
  const fields = props.fields;
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [showErrors, setShowErrors] = useState(true);

  useEffect(() => {
    setInitialValues(Object.fromEntries(Object.entries(fields).map(([label, { value }]) => [label, value])));
    const validationEntries = Object.fromEntries(Object.entries(fields).map(([label, { validation }]) => [label, validation]));
    setValidationSchema(Yup.object().shape({ ...validationEntries }));

    const gatewayPaths = ['/new-account', '/new-company-account', '/login', '/reset-password'];
    if (typeof window !== 'undefined') {
      if (gatewayPaths.includes(window.location.pathname)) {
        setShowErrors(false);
      }
    }
  }, []);

  return initialValues && validationSchema ? (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue }) => {
        return (
          <FormikForm className={`w-full`}>
            {Object.entries(values).map(([label]) => (
              <Field key={label} name={label}>
                {({ field: { value }, form: { errors, touched } }) => {
                  const { placeholder, component, type, title, optional } = fields[label] ? fields[label] : {};
                  const FieldComponent = component;
                  const error = (touched[label] && errors[label]) || (props.customErrors && props.customErrors[label]);

                  return (
                    <React.Fragment>
                      {title && (
                        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
                          <div>
                            {title}
                            {optional && <span className="uppercase text-xs text-darkgray">Optional</span>}
                          </div>
                          <div className="flex-1 border-b ml-md" />
                        </div>
                      )}
                      <FieldComponent
                        optional={optional}
                        type={type}
                        value={value}
                        update={(val) => {
                          setFieldValue(label, val);

                          if (props.customErrors && props.customErrors[label] && props.setCustomErrors) {
                            props.setCustomErrors({ ...props.customErrors, [label]: null });
                          }
                        }}
                        error={error}
                        setFieldValue={setFieldValue}
                        placeholder={placeholder ? placeholder : label}
                      />
                      {showErrors && error && label !== 'employment' && label !== 'education' && <ErrorMessage error={error} />}
                    </React.Fragment>
                  );
                }}
              </Field>
            ))}
            {props.children}
          </FormikForm>
        );
      }}
    </Formik>
  ) : null;
}
