import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/common';
import CreateAccountForm from '../../forms/gateway/CreateAccountForm';
import Logo from '../../images/header-logo.svg';
import ProfileIcon from '../../images/profile-icon.svg';
import '../../styles.css';

export default function CreateAccount({ credentials, navigation }) {
  const [loading, setLoading] = useState(false); /*eslint-disable-line*/

  useEffect(() => {
    !credentials && navigate('/login');
  }, []);

  return (
    <div className="create-account-js flex flex-col bg-lightgray justify-center items-center" style={{ height: '100vh', width: '100vw' }}>
      <div className="flex flex-col w-full h-full">
        <header className="w-full bg-white flex justify-center px-md" style={{ height: 80 }}>
          <div className="container flex items-center justify-between h-full">
            <img src={Logo} style={{ height: 50, margin: 0 }} />
            <img
              src={ProfileIcon}
              style={{
                height: 30,
                margin: 0,
                cursor: 'pointer',
              }}
            />
          </div>
        </header>
        <div className="overflow-auto flex-1 w-full flex justify-center">{credentials && <CreateAccountForm email={credentials.email} password={credentials.password} />}</div>
      </div>
      {loading && <Loading />}
    </div>
  );
}
