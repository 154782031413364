import React, { useState } from "react";
import { DaySelect, TimeSelect } from "../fields";
import Loader from "react-loader-spinner";

export default function InterviewAvailabilityForm({
  goBack,
  onSubmit,
  globalFormData,
}) {
  const [account] = globalFormData;
  const [loading, setLoading] = useState();
  const [showError, setShowError] = useState();
  const [days, setDays] = useState(
    account.availability && account.availability.days
  );
  const [time, setTime] = useState(
    account.availability && account.availability.schedule
  );

  function createAccount() {
    if (days && days.length > 0) {
      setLoading(true);
      const newRecruiter = {
        ...account,
        days,
        time,
        industries: account.industry,
      };
      onSubmit(newRecruiter);
    } else {
      setShowError("Please select the days of your work week");
    }
  }

  return (
    <div
      className="w-full flex flex-col items-center justify-end"
      style={{ maxWidth: 320 }}>
      <div className="flex-1">
        <div className="font-main mb-lg text-darkgray text-xs text-center">
          Please enter your general work week availability. You may edit this
          later.
        </div>
        <DaySelect value={days} update={setDays} />
        <TimeSelect value={time} update={setTime} />
      </div>
      {showError && (
        <div className="text-red text-sm text-center mb-md font-main font-medium">
          {showError}
        </div>
      )}
      <div className="flex w-full">
        <button
          onClick={goBack}
          className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-xs">
          Cancel
        </button>
        <button
          onClick={createAccount}
          className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded flex justify-center text-xs">
          {loading ? (
            <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} />
          ) : (
            "Save & Continue"
          )}
        </button>
      </div>
    </div>
  );
}
