import React, { useState, useEffect } from 'react';
import SelectDropdown from './SelectDropdown';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

const ALL_INDUSTRIES = gql`
  query allIndustries {
    industry {
      name
    }
  }
`;

export default function IndustrySelect({ update, value, error }) {
  const [selectedIndustries, setSelectedIndustries] = useState(value ? value : []);
  const [industries, setIndustries] = useState([]);
  const { data } = useQuery(ALL_INDUSTRIES);

  useEffect(() => {
    update(selectedIndustries);
  }, [selectedIndustries]);

  useEffect(() => {
    if (data) {
      setIndustries(data.industry.map(({ name }) => name));
    }
  }, [data]);

  return (
    <>
      <SelectDropdown
        error={error}
        update={(val) => val && setSelectedIndustries([...selectedIndustries, val])}
        placeholder="Industry"
        list={industries.filter((industry) => !selectedIndustries.includes(industry))}
      />
      {selectedIndustries.length > 0 && (
        <div className="flex flex-wrap" style={{ marginTop: 6, maxWidth: 320 }}>
          {selectedIndustries.map((industry) => (
            <div
              className="border flex font-main text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10 }}
            >
              {industry}
              <div
                onClick={() => setSelectedIndustries(selectedIndustries.filter((value) => value !== industry))}
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}
              >
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
