import { ApolloProvider, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import { request } from 'graphql-request';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import SnackbarProvider from 'react-simple-snackbar';
import SEO from '../../components/common/seo';
import { HTTP_HASURA_API_URL } from '../../config';
import { ConfirmModalContext, UserRoleContext } from '../../context';
import { ApolloContext } from '../../context/Apollo';
import ConfirmModal from '../../context/ConfirmModal';
import useApollo from '../../hooks/useApollo';
import useFirebase from '../../hooks/useFirebase';
import '../../styles.css';
import Gateway, { CreateAccount } from '../../views/gateway';
import './FilterStyles.css';
import Header from './header';
import './layout.css';

export const GlobalFilterContext = React.createContext();

const HAS_JOB_OR_CANDIDATES_SUBSCRIPTION = gql`
  subscription jobsCandidatesCheck($id: String) {
    recruiter(where: { id: { _eq: $id } }) {
      jobs_aggregate {
        aggregate {
          count
        }
      }
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const CHECK_USER_TYPE = `
  query recruiterCheck($email: String) {
    recruiter(where: { email: { _eq: $email } }) {
      email
      jobs_aggregate {
        aggregate {
          count
        }
      }
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
    company(where: { email: { _eq: $email } }) {
      email
      logoURL
    }
  }
`;

function PageLoading() {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
    </div>
  );
}

export const HasJobsOrCandidatesContext = React.createContext();

function AppContainer({ path, children, currentUser, profileModalState, apolloClient }) {
  const companyRoutes = [
    '/dashboard/find-candidate',
    '/dashboard/jobs',
    '/dashboard/completed-jobs',
    '/dashboard/requests-sent',
    '/dashboard/interested-candidates',
    '/checkout',
    '/saved-candidates',
    '/favorite-candidates',
    '/jobs/new-job',
  ];
  const [hasJobsOrCandidates, setHasJobOrCandidates] = useState(false);
  const [fade, setFade] = useState(false);
  const scrollContainerRef = useRef();
  const [userType, setUserType] = useState();
  const [modal, setModal] = useState({ data: null, open: false });
  const [globalFilter, setGlobalFilter] = useState({});
  const { data: jobsOrCandidatesData } = useSubscription(HAS_JOB_OR_CANDIDATES_SUBSCRIPTION, {
    variables: {
      id: currentUser ? currentUser.uid : 'no_user_logged_in',
    },
    shouldResubscribe: true,
  });

  const adminRoutes = [
    '/dashboard/candidates',
    '/dashboard/jobs',
    '/dashboard/job-queue',
    '/dashboard/recruiters',
    '/dashboard/subscribers',
    '/dashboard/companies',
    '/dashboard/attributes',
    '/dashboard/new-attribute',
    '/dashboard/reports',
    '/subscribers/new-subscriber',
    '/companies/new-company',
    '/candidates/new-candidate',
    '/recruiters/new-recruiter',
    '/jobs/new-job',
    '/dashboard/recruiter-activity',
    '/dashboard/subscriber-activity',
    '/dashboard/subscriber-credit-report',
    '/dashboard/candidate-introduction-report',
    '/dashboard/credit-transaction-history',
  ];

  useEffect(() => {
    if (jobsOrCandidatesData) {
      const { recruiter } = jobsOrCandidatesData;
      if (recruiter.length === 0 || recruiter[0].jobs_aggregate.aggregate.count !== 0 || recruiter[0].candidates_aggregate.aggregate.count !== 0) {
        setHasJobOrCandidates(true);
      }
    }
  }, [jobsOrCandidatesData]);

  useEffect(() => {
    setFade(true);
  }, []);

  useEffect(() => {
    if (userType && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [path]);

  useEffect(() => {
    if (currentUser) {
      request(HTTP_HASURA_API_URL, CHECK_USER_TYPE, {
        email: currentUser.email,
      })
        .then(({ recruiter, company }) => {
          setUserType(recruiter.length > 0 ? 'recruiter' : company.length > 0 ? 'company' : 'admin');

          if (company.length > 0 || recruiter.length === 0) {
            setHasJobOrCandidates(true);
          }
          if (recruiter.length > 0) {
            if (recruiter[0].jobs_aggregate.aggregate.count !== 0 || recruiter[0].candidates_aggregate.aggregate.count !== 0) {
              setHasJobOrCandidates(true);
            }
          }
        })
        .catch(console.log);
    }
  }, [currentUser]);

  if (!userType) {
    return <PageLoading />;
  }

  if (
    userType === 'company' &&
    !companyRoutes.includes(path) &&
    ((path.split('/')[1] === 'jobs' && path.split('/')[2] === 'open-jobs') ||
      (path.split('/')[1] === 'jobs' && path.split('/')[2] === 'all-jobs') ||
      (path.split('/')[1] === 'jobs' && path.split('/')[2] === 'completed-jobs') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'subscriber-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'subscriber-credit-report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'candidate-introduction-report') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'credit-transaction-history') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-credit-activity') ||
      (path.split('/')[1] === 'dashboard' && path.split('/')[2] === 'recruiter-job-output'))
  ) {
    navigate('/dashboard/find-candidate');
    return <PageLoading />;
  }

  if (
    userType === 'recruiter' &&
    (path === '/saved-candidates' || path === '/favorite-candidates' || path === '/checkout' || path === '/dashboard/job-queue' || path === '/dashboard/jobs')
  ) {
    navigate('/dashboard/candidates-in-process');
    return <PageLoading />;
  }

  if (
    userType === 'admin' &&
    !adminRoutes.includes(path) &&
    path.split('/').length === 3 &&
    ![
      'jobs',
      'job-queue',
      'recruiters',
      'candidates',
      'subscribers',
      'companies',
      'reports',
      'attributes',
      'recruiter-activity',
      'subscriber-activity',
      'subscriber-credit-report',
      'candidate-introduction-report',
      'credit-transaction-history',
    ].includes(path.split('/')[1])
  ) {
    navigate('/dashboard/candidates');
    return <PageLoading />;
  }

  let seoTitle = '';

  if (path.split('/').length > 2) {
    seoTitle =
      path
        .split('/')[1]
        .split('')[0]
        .toUpperCase() + path.split('/')[1].substr(1);
  }

  return (
    <SnackbarProvider>
      <UserRoleContext.Provider value={[userType, setUserType]}>
        <ConfirmModalContext.Provider value={[modal, setModal]}>
          <GlobalFilterContext.Provider value={{ globalFilter, setGlobalFilter }}>
            <HasJobsOrCandidatesContext.Provider value={[hasJobsOrCandidates, setHasJobOrCandidates]}>
              <ApolloContext.Provider value={{ apolloClient }}>
                <ConfirmModal>
                  <div id="page-container" className={`flex flex-col h-screen items-center font-main bg-lightgray ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    <SEO title={seoTitle} />
                    <Header path={path} currentUser={currentUser} profileModalState={profileModalState} userRoleState={[userType, setUserType]} />
                    <main ref={scrollContainerRef} style={{ minHeight: 'calc(100vh - 80px)', marginTop: 80 }} className="container font-main relative px-md lg:px-0 flex flex-col">
                      {currentUser && children}
                    </main>
                  </div>
                </ConfirmModal>
              </ApolloContext.Provider>
            </HasJobsOrCandidatesContext.Provider>
          </GlobalFilterContext.Provider>
        </ConfirmModalContext.Provider>
      </UserRoleContext.Provider>
    </SnackbarProvider>
  );
}

const gatewayPaths = ['/login', '/new-account', '/new-company-account', '/reset-password', '/cpcjadmin320'];

function ApolloWrapper({ children, currentUser, initializingFirebase, location, navigate, isProfileModalVisible, toggleProfileModal, apolloClient }) {
  if (!initializingFirebase) {
    if (currentUser) {
      if (gatewayPaths.includes(location.pathname)) {
        navigate('/dashboard/');
        return <PageLoading />;
      } else {
        return (
          <AppContainer path={location.pathname} currentUser={currentUser} profileModalState={[isProfileModalVisible, toggleProfileModal]} apolloClient={apolloClient}>
            {children}
          </AppContainer>
        );
      }
    } else if (location.pathname === '/create-account') {
      if (location.state) {
        const { email, password } = location.state;
        return <CreateAccount credentials={{ email, password }} />;
      } else {
        navigate('/login');
        return null;
      }
    } else if (!gatewayPaths.includes(location.pathname)) {
      navigate('/login');
      return <PageLoading />;
    } else {
      return <Gateway path={location.pathname} />;
    }
  } else {
    return <PageLoading />;
  }
}

export default function Layout({ children, location }) {
  const [isProfileModalVisible, toggleProfileModal] = useState(false);
  const { initializingFirebase, currentUser, token } = useFirebase();
  const client = useApollo(token);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/8833398.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return client ? (
    <ApolloProvider client={client}>
      <ApolloWrapper
        apolloClient={client}
        children={children}
        location={location}
        currentUser={currentUser}
        initializingFirebase={initializingFirebase}
        navigate={navigate}
        isProfileModalVisible={isProfileModalVisible}
        toggleProfileModal={toggleProfileModal}
      />
    </ApolloProvider>
  ) : (
    <PageLoading />
  );
}
