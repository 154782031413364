import { Field } from 'formik';
import React, { useEffect } from 'react';
import TenureField from './TenureField';
import TextInput from './TextInput';

const EducationEntry = ({ value, update, error }) => {
  const placeholders = {
    school: 'School Name',
    degree: 'Degree',
  };

  const renderField = (name, value, index) => {
    switch (name) {
      case 'id':
        return null;
      case 'tenure':
        return <TenureField key={index} value={value} update={(value) => update({ name, data: value })} isEducation={true} />;

      default:
        return <TextInput key={index} error={!value && error} value={value} placeholder={placeholders[name]} update={(value) => update({ name, data: value })} />;
    }
  };

  return <div className="flex flex-wrap">{Object.entries(value).map(([label, field], index) => renderField(label, field, index))}</div>;
};

export default function EducationField({ value, update, error, newResumeValue }) {
  const blankEducationEntry = {
    id:
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15),
    school: '',
    degree: '',
    tenure: {
      startDate: '',
      endDate: '',
    },
  };

  const addExperience = () => update([...value, blankEducationEntry]);
  const removeExperience = (index) => update(value.filter((_, i) => index !== i));

  const updateEntry = ({ name, data }, index) => {
    const updatedEntry = Object.fromEntries(Object.entries(value[index]).map(([label, entry]) => (label === name ? [name, data] : [label, entry])));
    const updatedList = value.map((_, i) => (i === index ? updatedEntry : value[i]));
    update(updatedList);
  };

  useEffect(() => {
    if (newResumeValue) {
      update(newResumeValue);
    }
  }, [newResumeValue]);

  return (
    <div>
      {error && <div className="text-red font-medium text-sm mt-sm text-center">{error}</div>}
      {value.map((educationEntry, index) => (
        <Field key={index} name={`education[${index}]`}>
          {({ form: { errors, touched } }) => (
            <>
              <EducationEntry key={educationEntry.id} error={error} value={educationEntry} update={(val) => updateEntry(val, index)} />
              {value.length > 1 && (
                <>
                  <div className="text-red flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
                    <div onClick={() => removeExperience(index)} className="flex items-center py-sm px-md hover:bg-red hover:text-white rounded cursor-pointer">
                      <span style={{ fontSize: 24, marginRight: 7 }}>-</span>
                      <span>Remove Education Experience</span>
                    </div>
                  </div>
                  <div className="border-b" style={{ marginTop: 20 }} />
                </>
              )}
            </>
          )}
        </Field>
      ))}
      <div className="text-green flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
        <div onClick={addExperience} className="flex items-center py-sm px-md hover:bg-green hover:text-white rounded cursor-pointer">
          <span style={{ fontSize: 24, marginRight: 7 }}>+</span>
          <span>Add Education Experience</span>
        </div>
      </div>
    </div>
  );
}
