import React, { useState, useEffect } from "react";
import SelectDropdown from "./SelectDropdown";
import NewCompanyForm from "./NewCompanyForm";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ALL_COMPANIES_QUERY = gql`
  {
    company(where: { adminID: { _is_null: true } }) {
      id
      logoURL
      name
    }
  }
`;

export default function CompanyField({ update, error, value }) {
  const [isNewCompany, toggleIsNewCompany] = useState(false);
  const { data } = useQuery(ALL_COMPANIES_QUERY);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(value);

  useEffect(() => {
    update(company);
  }, [company]);

  useEffect(() => {
    if (data) {
      setCompanies(data.company);
    }
  }, [data]);

  return (
    <>
      {isNewCompany ? (
        <NewCompanyForm
          showErrors={error}
          update={setCompany}
          cancel={() => toggleIsNewCompany(false)}
        />
      ) : (
        <SelectDropdown
          value={value && value.name}
          error={error}
          action={e => toggleIsNewCompany(!isNewCompany)}
          update={setCompany}
          list={companies}
          placeholder="Company"
        />
      )}
      {error && !isNewCompany && (
        <div className="text-xs text-red font-medium mt-sm text-center">
          {error}
        </div>
      )}
    </>
  );
}
