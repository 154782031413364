import React from 'react';
import { CompensationDropdown } from './AttributesDropdown';

const Xbutton = ({ close }) => {
  return (
    <div onClick={close} className="ml-sm text-sm relative" style={{ left: 5, width: 10, height: 10 }}>
      <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
      <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
    </div>
  );
};

export function CompensationField({ update, error, value }) {
  return (
    <>
      {value.length > 0 && (
        <div className="flex flex-wrap" style={{ maxWidth: 310 }}>
          {value.map((attribute, index) => (
            <div
              key={index}
              className="px-md cursor-pointer bg-lightgray mt-sm flex justify-between mr-sm font-medium text-darkgray items-center"
              style={{ fontSize: 12, height: 28, borderRadius: 28 }}
            >
              {attribute}
              <Xbutton
                close={() => {
                  update(value.filter((attr) => attr !== attribute));
                }}
              />
            </div>
          ))}
        </div>
      )}
      <div style={{ height: 55, marginTop: 20 }}>
        <CompensationDropdown className={`w-full relative rounded border placeholder-primary text-sm px-md`} value={value} update={update} error={error} />
      </div>
    </>
  );
}
