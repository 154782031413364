import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Arrow from '../../components/common/Arrow';
import PhotoUpload from './PhotoUpload';

const CompanySizeSelect = ({ update, showError }) => {
  const options = ['1-4', '5-9', '10-19', '20-49', '50-99', '100-249', '500-999', '1000+'];
  const [size, setSize] = useState('Number of employees');

  useEffect(() => {
    update(size);
  }, [size]);

  return (
    <div className="relative font-main w-full">
      <select
        value={size}
        onChange={(e) => setSize(e.target.value)}
        placeholder="Number of employees"
        className="bg-white mt-md cursor-pointer px-md border-lightgray text-sm w-full rounded border"
        style={{
          WebkitAppearance: 'none',
          height: 55,
          borderColor: showError && size === 'Number of employees' ? '#E31C25' : '#c4cad3',
          color: size === 'Number of employees' && 'rgb(196, 202, 211)',
          marginTop: 20,
        }}
      >
        <option disabled>Number of employees</option>
        {options.map((option) => (
          <option>{option}</option>
        ))}
      </select>
      <div className="absolute" style={{ right: 20, top: 40, transform: 'rotate(-90deg)' }}>
        <Arrow color="#9499A2" />
      </div>
    </div>
  );
};

export default function NewCompanyForm({ cancel, update, showErrors }) {
  // const companySchema = Yup.object().shape({
  //     logoURL: Yup.mixed().required('A company logo is required'),
  //     name: Yup.string().required('A company name is required'),
  //     size: Yup.string().required('Please select Number of employees'),
  //     bio: Yup.string().required('A short company bio is required'),
  //     websiteURL: Yup.string().required('A company website is required'),
  // })

  const fieldsInitialState = {
    logoURL: '',
    name: '',
    size: '',
    bio: '',
    websiteURL: '',
  };

  const submitRef = useRef();

  return (
    <div className="flex flex-col" style={{ maxWidth: 320 }}>
      <div className="text-green w-full font-main text-sm relative font-medium mt-sm flex justify-between items-center" style={{ top: 5 }}>
        New Company
        <div onClick={cancel} className="cursor-pointer text-darkgray text-lg hover:text-red">
          x
        </div>
      </div>
      <Formik onSubmit={update} initialValues={fieldsInitialState}>
        {({ values, setFieldValue }) => (
          <Form>
            {Object.entries(values).map(([label]) => {
              update(values);
              switch (label) {
                case 'logoURL':
                  return (
                    <Field name="logoURL">
                      {({ form: { errors } }) => (
                        <React.Fragment>
                          <PhotoUpload
                            placeholder="Company Logo"
                            update={(value) => {
                              setFieldValue(label, value);
                              submitRef.current.click();
                            }}
                            label={label}
                          />
                          {showErrors && errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{errors[label]}</div>}
                        </React.Fragment>
                      )}
                    </Field>
                  );
                case 'size':
                  return (
                    <Field name="size">
                      {({ form: { errors } }) => (
                        <React.Fragment>
                          <CompanySizeSelect
                            showError={showErrors && errors[label]}
                            update={(value) => {
                              setFieldValue(label, value);
                              submitRef.current.click();
                            }}
                          />
                          {showErrors && errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{errors[label]}</div>}
                        </React.Fragment>
                      )}
                    </Field>
                  );
                default:
                  return (
                    <Field name={label}>
                      {({ form: { errors } }) => (
                        <React.Fragment>
                          <input
                            onChange={(e) => {
                              setFieldValue(label, e.target.value);
                              submitRef.current.click();
                            }}
                            placeholder={`Company ${label.charAt(0).toUpperCase() + label.slice(1)}`}
                            className="w-full font-main w-full rounded border placeholder-primary text-sm px-md"
                            style={{
                              height: 55,
                              borderColor: showErrors && errors[label] ? '#E31C25' : '#c4cad3',
                              marginTop: 20,
                            }}
                          />
                          {showErrors && errors[label] && <div className="text-xs text-red font-medium mt-sm text-center">{errors[label]}</div>}
                        </React.Fragment>
                      )}
                    </Field>
                  );
              }
            })}
            <button type="submit" ref={submitRef} className="hidden">
              submit company
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
