import React from "react";
import { TextInput } from "./";

export default function ExperienceFilter({ value, update, error }) {
  const updateFilter = (type, amount) => {
    update({
      ...value,
      [type]: amount
    })
  }

  return (
    <div
      className="flex items-center text-center"
      style={{ paddingLeft: 4}}>
      <div className="relative" style={{width:'49%'}}>
        <TextInput
            error={error && !value[0]}
            placeholder='Min'
            value={value[0]}
            type='number'
            update={val => update([val, value[1]])}
        />
      </div>
      <div className="flex items-center mx-sm" style={{marginTop: 20}}>
      -
      </div>
      <div className="relative" style={{width:'49%'}}>
        <TextInput
            error={error && !value[1]}
            placeholder='Max'
            type='number'
            value={value[1]}
            update={val => update([value[0], val])}
        />
        {/* <div
          className="text-xs absolute text-darkgray bg-white"
          style={{ right: 10, top: 37 }}>
          Years
        </div> */}
      </div>
    </div>
  );
}
