import React from "react";

export default function ErrorMessage({ error }) {
  return (
    <div
      className="text-xs font-main text-red font-medium mt-sm text-center"
      style={{ minWidth: 320, maxWidth: 320 }}
    >
      {error}
    </div>
  );
}
